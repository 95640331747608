import { useTranslation } from "react-i18next";
import Xbox from "../common/Xbox";
import Notify from "../common/Notify";
import { Box, Grid } from "@mui/material";

const ShopNow = () => {
  const { t } = useTranslation("", { keyPrefix: "Newmove.ShopNow" });
  return (
    <>
      <div style={{ backgroundColor: "#eeeeee" }}>
        <Xbox
          title={t("title")}
          description={t("description")}
          imgSrc={t("img.src")}
          sx={{
            mt: { xs: 0, md: 10, lg: 2 },
            pt: { xs: 2, md: 2, lg: 6 },
            textAlign: "center",
            ".MuiTypography-h1": {
              px: { xs: 2, md: 2, lg: 2 },
            },
            ".MuiGrid-container": {
              pb: 0,
            },
            ".MuiTypography-body1": {
              px: { xs: 2, md: 10, lg: 28 },
            },
          }}
        />
        <Grid
          container
          data-aos="fade-up"
          item
          xs={12}
          md={5}
          lg={8}
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "initial", lg: "initial" },
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={t("img.src")} alt={t("img.alt")} style={{ width: "100%", maxWidth: "500px" }} />
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              gap: "16px",
            }}
          >
            <Notify
              sx={{
                mt: { xs: 4, md: 4, lg: 0 },
                mb: { xs: 0, md: 4, lg: 12 },
                px: { xs: 8, md: 4, lg: 12 },
              }}
              productId={""}
              data-aos="fade-up"
            />
          </Box>
        </Grid>
      </div>
    </>
  );
};

export default ShopNow;
