import { Helmet } from "react-helmet-async";
import { TFunction } from "react-i18next";
import i18next from "i18next";

type MetaTagsProps = {
  t: TFunction;
};

type Tags = {
  title: string;
  description: string;
  keywords:string;
  og: {
    type: string;
    image?: {
      url: string;
      alt: string;
    };
  };
};

const MetaTags = ({ t }: MetaTagsProps) => {
  const tags: Tags = t("head", { returnObjects: true });

  // To get the url without hash
  const domain = window.location.origin;
  const canonical = domain + window.location.pathname;
  const sitename = "new move energy";
  const currentLanguage = i18next.resolvedLanguage;

  if (tags.og.image)
    return (
      <Helmet>
        {/* Meta tags */}
        <meta name="description" content={tags.description} />
        <meta name="keywords" content={tags.keywords} />
        
        {/* Open Graph tags */}
        <meta property="og:site_name" content={sitename} />
        <meta property="og:locale" content={currentLanguage} />
        <meta property="og:title" content={tags.title} />
        <meta property="og:url" content={canonical} />
        <meta property="og:description" content={tags.description} />
        <meta property="og:type" content={tags.og.type} />
        <meta property="og:image" content={domain + tags.og.image.url} />
        <meta property="og:image:alt" content={tags.og.image.alt} />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={""} />
        <meta name="twitter:title" content={tags.title} />
        <meta name="twitter:description" content={tags.description} />
        <meta name="twitter:image" content={domain + tags.og.image.url} />
        <meta name="twitter:image:alt" content={tags.og.image.alt} />

        <title>{t("head.title")}</title>
      </Helmet>
    );

  return (
    <Helmet>
      {/* Meta tags */}
      <meta name="description" content={tags.description} />
      <meta name="keywords" content={tags.keywords} />

      {/* Open Graph tags */}
      <meta property="og:site_name" content={sitename} />
      <meta property="og:locale" content={currentLanguage} />
      <meta property="og:title" content={tags.title} />
      <meta property="og:url" content={canonical} />
      <meta property="og:description" content={tags.description} />
      <meta property="og:type" content={tags.og.type} />

      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={""} />
      <meta name="twitter:title" content={tags.title} />
      <meta name="twitter:description" content={tags.description} />

      <title>{t("head.title")}</title>
    </Helmet>
  );
};

export default MetaTags;
