import React from "react";
import { Box, Typography, Button, Grid, Container } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { AppStore, GooglePlay } from "../../static/assets/icons";

const NewmoveApps: React.FC = () => {
  const { t } = useTranslation("", { keyPrefix: "Newmove.OurApps" });
  const title = (
    <Trans t={t} components={{ span: <span /> }}>
      {t("title")}
    </Trans>
  );

  return (
    <div style={{ backgroundColor: "#eeeeee", paddingBottom: "10px" }}>
      <Container sx={{ display: "flex", alignItems: "center", flexDirection: { xs: "column-reverse", md: "inherit" } }}>
        <Box>
          <Typography
            variant="h1"
            component="h2"
            data-aos="fade-up"
            sx={{
              textAlign: { xs: "center", md: "left" },
              pl: { xs: "0px", md: "0px", lg: "0px" },
              pr: { xs: "0px", md: "150px", lg: "150px" },
              pt: { xs: "6px", md: "30px" },
              fontSize: { xs: "2.2rem", lg: "3rem" },
              color: "#474747",
              span: {
                color: "primary.main",
              },
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              textAlign: { xs: "center", md: "left" },
              pl: { xs: "20px", md: "0px" },
              pr: { xs: "20px", md: "120px" },
              pt: { xs: "20px", md: "20px" },
            }}
            variant="body1"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            {t("description")}
          </Typography>

          <Box sx={{ display: { xs: "grid", md: "inline-grid", lg: "inline-grid" } }} mt={2}>
            <Button startIcon={<GooglePlay />} href={t("storeLink.googlePlay")} target="_blank" />
            <Button startIcon={<AppStore />} href={t("storeLink.appStore")} target="_blank" />
          </Box>
        </Box>
        <Grid container data-aos="fade-up">
          <img style={{ paddingTop: "40px" }} src={t("img.src")} alt={t("img.alt")} />
        </Grid>
      </Container>
    </div>
  );
};

export default NewmoveApps;
