import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Container, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation, Trans } from "react-i18next";

const ShellyInfoTable: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation("", { keyPrefix: "Newmove.TableSection" });
  const title = (
    <Trans t={t} components={{ span: <span /> }}>
      {t("title")}
    </Trans>
  );

  const columns = t("columns", { returnObjects: true }) as { [key: string]: string };
  const rows = t("rows", { returnObjects: true }) as Array<{
    id: number;
    data: { [key: string]: string };
  }>;

  const commonCellStyles = {
    padding: "0.5rem",
    fontWeight: "bold",
    color: "#333333",
  };

  const renderTableHead = () => (
    <TableHead>
      <TableRow sx={{ backgroundColor: "#FFC040" }}>
        {isSmallScreen ? (
          <>
            <TableCell sx={commonCellStyles}>{t("columns.col1")}</TableCell>
            {rows.map((row) => (
              <TableCell key={row.id} sx={commonCellStyles}>
                {row.data["col1"]}
              </TableCell>
            ))}
          </>
        ) : (
          Object.keys(columns).map((key) => (
            <TableCell key={key} sx={commonCellStyles}>
              {columns[key]}
            </TableCell>
          ))
        )}
      </TableRow>
    </TableHead>
  );

  const renderTableBody = () =>
    isSmallScreen
      ? Object.keys(columns)
          .slice(1)
          .map((colKey, colIndex) => (
            <TableRow key={colIndex}>
              <TableCell sx={commonCellStyles}>{columns[colKey]}</TableCell>
              {rows.map((row) => (
                <TableCell key={row.id} sx={{ color: "#0F0F0F" }}>
                  {row.data[colKey]}
                </TableCell>
              ))}
            </TableRow>
          ))
      : rows.map((row) => (
          <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            {Object.keys(columns).map((key) => (
              <TableCell
                key={key}
                sx={{
                  ...commonCellStyles,
                  fontWeight: key === "col1" ? "bold" : "normal",
                  color: key === "col1" ? "#333333" : "#0F0F0F",
                }}
              >
                {row.data[key]}
              </TableCell>
            ))}
          </TableRow>
        ));

  const renderTable = () => (
    <Table
      sx={{
        width: "100%",
        minWidth: isSmallScreen ? "auto" : 650,
        borderRadius: 2,
        "& th, & td": {
          padding: isSmallScreen ? "0.5rem" : "1rem",
          fontSize: isSmallScreen ? "0.8rem" : "1rem",
        },
        tableLayout: "auto",
      }}
      aria-label="custom table"
    >
      {renderTableHead()}
      <TableBody>{renderTableBody()}</TableBody>
    </Table>
  );

  return (
    <Container sx={{ mb: { xs: "6%", md: "5%" } }}>
      <Typography
        variant="h1"
        component="h2"
        data-aos="fade-up"
        sx={{
          pb: { xs: "20px", md: "40px" },
          fontSize: { xs: "2.2rem", lg: "3rem" },
          color: "#474747",
          span: {
            color: "primary.main",
          },
        }}
      >
        {title}
      </Typography>
      <Box style={{ margin: "0 auto", overflowX: "hidden" }}>
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "8px",
            width: "100%",
            boxShadow: "none",
            border: "1px solid #D6D6D6",
          }}
        >
          {renderTable()}
        </TableContainer>
      </Box>
    </Container>
  );
};

export default ShellyInfoTable;
