import styled from "@emotion/styled";
import { Box, Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const Rec1 = styled(Box)({
  width: "500px",
  height: "20px",
  backgroundColor: "#000000",
});
const Rec2 = styled(Box)({
  width: "500px",
  height: "20px",
  backgroundColor: "#dd0000",
});
const Rec3 = styled(Box)({
  width: "500px",
  height: "20px",
  backgroundColor: "#ffce00",
});

const MadeInGermany = () => {
  const { t } = useTranslation("", { keyPrefix: "Newmove.Manufacturer" });

  return (
    <div style={{ backgroundColor: "#eeeeee", paddingTop: "20px" }}>
      <Typography
        variant="h1"
        component="h2"
        data-aos="fade-up"
        sx={{
          padding: { xs: "20px", md: "60px" },
          fontSize: { xs: "2.2rem", lg: "3rem" },
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          color: "#474747",
          fontWeight: "700",
          span: {
            color: "primary.main",
          },
        }}
      >
        {t("TUV-certification.title")}
      </Typography>
      <Typography
        sx={{
          mx: { xs: 4, md: 22, lg: 40 },
          textAlign: "center",
        }}
      >
        {t("TUV-certification.description")}
      </Typography>
      <Container
        sx={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          paddingTop: { xs: 4, lg: "60px" },
        }}
      >
        <Rec1 />
        <Rec2 />
        <Rec3 />
      </Container>
      <Container
        data-aos="fade-up"
        sx={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          paddingTop: { xs: 3, lg: 2 },
          fontWeight: "bold",
          fontSize: { xs: "24px", lg: "36px" },
          letterSpacing: { xs: "2px", lg: "14px" },
        }}
      >
        {t("madeInGermany.title")}
      </Container>
      <Container data-aos="fade-up" sx={{ display: { lg: "flex" }, paddingBottom: { xs: "20px" } }}>
        <Container
          sx={{
            paddingTop: "8px",
            padding: { xs: "15px", md: "20px", lg: "50px" },
            mx: { xs: 0, md: 20, lg: 23 },
            textAlign: "center",
          }}
        >
          {t("madeInGermany.description")}
        </Container>
      </Container>
    </div>
  );
};

export default MadeInGermany;
